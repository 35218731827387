<template>
  <div>
    <Loading type="line" :center="false" v-show="loading" />
    <Alert type="info" v-if="statusStock != 1 && statusStock != 2">
      Produto com estoque em movimentação, não é permitido alterar alguns campos!
    </Alert>
    <div>
      <b-row>
        <b-col sm="6">
          <Select title="Grupo" field="groupRent" :required="false" :markFormDirty="false" v-model="itemsRent.groupRent"
            url="/api/v1/rental/group-rent/select-all" nameNewRegister="groupRentCreateUpdate" titleNewRegister="Grupo"
            :widthNewRegister="500" :heightNewRegister="250">
            <GroupRentCreateUpdate :registerInSelect="true" v-model="itemsRent.groupRent" />
          </Select></b-col>
        <b-col sm="6">
          <Select title="Período de Locação" field="type" :required="true" :markFormDirty="false"
            :disabled="statusStock != 1 && statusStock != 2" :formName="formName"
            :propsParams="{ any: itemsRent.rentId }" :showNewRegister="false"
            url="/api/v1/rental/period-rent/select-all" v-model="itemsRent.periodRent">
          </Select></b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="5" xl="5">
          <Select title="Tipo" field="type" :formName="formName" :required="true"
            :disabled="statusStock != 1 && statusStock != 2" :markFormDirty="false"
            url="/api/v1/stock/type-product/select-all" :showNewRegister="false" v-model="itemsRent.typeProduct"
            :changed="cleanProduct" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="7" xl="7">
          <Select title="Produto" field="product" :formName="formName" :required="true" :markFormDirty="false"
            url="/api/v1/stock/product/select-all" :propsParams="{ any: itemsRent.typeProduct.id }"
            :disabled="isDisabledProduct" :showNewRegister="false" v-model="itemsRent.product" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div>
            <EditorHtml title="Descrição" field="description" :required="false" :markFormDirty="false" :maxLength="2000"
              v-model="itemsRent.description" />
          </div>
        </b-col>
      </b-row>
      <Alert type="info" v-if="statusInvoice == 2">
        Produto faturado, não é permitido alterar condiçoes comerciais!
      </Alert>
      <b-row>
        <b-col sm="2">
          <InputNumber title="Quantidade" field="quantity" :formName="formName" :required="false"
            :disabled="statusStock == 5 || statusStock == 6 || statusInvoice == 2" :markFormDirty="false" :maxLength="5"
            type="float" v-model="itemsRent.quantity" />
        </b-col>
        <b-col sm="3">
          <InputDecimal title="Valor Unitário" field="unitValue" :formName="formName" :required="false"
            :markFormDirty="false" :disabled="statusInvoice == 2" :maxLength="10" :precision="8" type="float"
            v-model="itemsRent.unitValue" />
        </b-col>
        <b-col sm="3">
          <InputDecimalDiscount title="Desconto Unitário" field="unitDiscount" :formName="formName" :required="false"
            _style="color:red;" :disabled="statusInvoice == 2" :maxLength="8" :precision="8" type="float"
            v-model="itemsRent.discount" />
        </b-col>
        <b-col sm="2">
          <InputDecimal title="Custo Unitário" field="unitCost" :formName="formName" :required="false"
            :markFormDirty="false" :disabled="statusInvoice == 2" _style="color:red;" :maxLength="10" :precision="2"
            type="float" v-model="itemsRent.unitCost" />
        </b-col>
        <b-col sm="2">
          <InputDecimal title="Seguro Unitário" field="unitInsurance" :formName="formName" :required="false"
            :markFormDirty="false" :disabled="statusInvoice == 2" :maxLength="10" :precision="2" type="float"
            v-model="itemsRent.unitInsurance" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="div-btn text-right">
            <Button _key="btnUpdateItemRent" type="success" title="Salvar" :disabled="!isFormValid(formName)"
              classIcon="fas fa-save" size="medium" :clicked="saveItemRent" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ItemsRent from "@/components/modules/rental/items-rent/ItemsRent.js";
import GroupRentCreateUpdate from "../group-rent/GroupRentCreateUpdate.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemRentUpdate",
  components: {
    InputDecimalDiscount,
    InputNumber,
    InputDecimal,
    Select,
    EditorHtml,
    Button,
    Loading,
    Alert,
    GroupRentCreateUpdate,
  },
  data() {
    return {
      formName: "itemRentUpdate",
      itemsRent: new ItemsRent(),
      urlUpdate: "/api/v1/rental/items-rent/update",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    statusStock() {
      return this.itemsRent.statusStockItemsRent;
    },
    statusInvoice() {
      return this.itemsRent.statusInvoiceItemsRent;
    },
    isDisabledProduct() {
      if (this.itemsRent.typeProduct.id == "") return true;
      if (this.statusStock == 1) return false;
      if (this.statusStock == 2) return false;

      return true;
    }
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveItemRent() {
      let discount = this.itemsRent.discount;
      this.itemsRent.discountByPercentage = discount.discountByPercentage;
      this.itemsRent.unitDiscount = discount.discount;

      let periodRentIdOld = this.itemsRent.periodRentId;
      this.itemsRent.periodRentId = this.itemsRent.periodRent.id;
      this.itemsRent.productId = this.itemsRent.product.id;

      let params = { url: this.urlUpdate, obj: this.itemsRent };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({
            name: "addedProduct",
            data: { showAll: false, period: { id: periodRentIdOld } },
          });
          this.addEvent({ name: "updateTotalizationRent" });
          this.hideModal();
        }
        this.removeLoading(["btnUpdateItemRent"]);
      });
    },
    update(data) {
      this.itemsRent = new ItemsRent();
      this.itemsRent.update(data);
    },
    cleanProduct() {
      this.itemsRent.product = { id: "", content: "" };
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "itemRentUpdate") {
          this.update(event.data);
          this.loading = true;
          let self = this;
          setTimeout(function () {
            self.loading = false;
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
