<template>
  <div>
    <DisplayTotalization title="Sub-Total" :value="totalization.totalValue" />
    <DisplayTotalization v-if="totalization.totalInsurance > 0" title="Seguro" :value="totalization.totalInsurance"
      _style="color: #808080" />
    <DisplayTotalization title="Desconto" :discount="totalization.percentageDiscount.toFixed(1)"
      :value="totalization.totalDiscount" _style="color: red" :clicked="editDiscount" />
    <DisplayTotalization v-if="totalization.totalCost > 0" title="Custo" :value="totalization.totalCost"
      _style="color: red" />
    <DisplayTotalization title="TOTAL GERAL" :value="totalization.totalGeneral" />
    <Modal title="Desconto" :width="500" :height="750" v-if="showModal('discount')">
      <Alert type="info">
        <span>
          Não será aplicado desconto em produto faturado!
        </span>
      </Alert>
      <div class="div-discount">
        <InputDecimalDiscount title="Desconto Unitário" :markFormDirty="false" field="unitDiscount" :required="false"
          _style="color:red;" :maxLength="5" type="float" v-model="discount" />
        <div class="div-apply text-center">
          <Button _key="btnApplyDiscount" title="Aplicar" backGroundColor="#F36944" color="white" size="medium"
            :clicked="applyDiscount" />
        </div>
      </div>
    </Modal>
    <!--<div class="div-total-payment" v-if="totalization.totalGeneral != totalization.totalPayment">
      <Alert type="warning" v-if="statusRental == 2">
        O Valor Total da Locação <b>{{ totalization.totalGeneral | currency }}</b> está diferente do Valor Total da
        Condição de Pagamento <b>{{ totalization.totalPayment | currency }}</b>.
      </Alert>
    </div> -->
  </div>
</template>
<script>
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";
import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "RentTotalizationRent",
  components: { DisplayTotalization, InputDecimalDiscount, Modal, Button, Molded, Alert },
  props: {
    rentId: String,
    statusRental: Number,
    value: Number
  },
  data() {
    return {
      urlGetTotalization: "/api/v1/rental/rent/totalization",
      urlUpdate: "/api/v1/rental/rent/update-discount",
      totalization: {},
      discount: { discountByPercentage: false, discount: 0 },
    };
  },
  created() {
    this.getTotalization();
  },
  computed: {
    ...mapState("generic", ["event", "methodExecutedApi"]),
    ...mapGetters("generic", ["showModal"]),
  },
  methods: {
    ...mapActions("generic", ["getApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "openModal", "hideModal", "removeLoading"]),
    getTotalization() {
      let params = { url: this.urlGetTotalization, obj: { rentId: this.rentId } };
      this.getApi(params).then((response) => {
        this.totalization = response.content;
        this.discount.discount = this.totalization.totalDiscount;
        this.discount.discountByPercentage = false;
      });
    },
    editDiscount() {
      this.openModal("discount");
    },
    applyDiscount() {
      let params = {
        url: this.urlUpdate, obj: {
          id: this.rentId,
          discountByPercentage: this.discount.discountByPercentage,
          discount: this.discount.discount
        }
      };
      this.putApi(params).then((response) => {
        if (response.success) {
          let self = this;
          setTimeout(function () {
            self.addEvent({ name: "updateItemsRent" });
            self.getTotalization();
            self.hideModal();
          }, 100);
        }
        this.removeLoading(["btnApplyDiscount"]);
      });
    }
  },
  watch: {
    methodExecutedApi: {
      handler(methodExecutedApi) {
        if (methodExecutedApi) this.getTotalization();
      },
      deep: true,
    },
    event: {
      handler(event) {
        if (event.name == "updateTotalizationRent"
          || event.name == "itemsImported")
          this.getTotalization();
      },
      deep: true,
    },
    discount: {
      handler(discount) {
        this.$emit("input", discount);
      },
      deep: true,
    },
    value: {
      handler(discount) {
        this.discount = this.value;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-discount {
  margin-right: 25px;
}

.div-products {
  margin-bottom: 10px;
}

.div-total-payment {
  margin-bottom: 30px;
}


.title-discount {
  color: red;
}

.div-apply {
  margin-top: 10px;
}
</style>
