<template>
  <div>
    <div>
      <div v-show="!selectedPeriod.period">
        <Alert type="warning">
          <span> É necessário adicionar o Período de Locação!</span>
        </Alert>
      </div>
      <div v-show="selectedPeriod.period">
        <SelectOptionPeriodRent :rentId="rent.id" v-model="selectedPeriod" />
        <Button _key="btnItemsRentCreateUpdate" title="Produto" classIcon="fas fa-plus-circle" type="primary"
          size="small" :disabled="selectedPeriod.showAll" :clicked="create" />
        <div class="side-by-side">
          <ImportProductByRent v-if="selectedPeriod.period" module="rent" :targetId="rent.id"
            :periodRentId="selectedPeriod.period.id" />
        </div>
      </div>
    </div>
    <Modal title="Produtos" :width="1200" :height="750" v-show="showModal('addProduct')"
      :onHideModal="hideSearchProduct">
      <SearchProduct v-if="showSearchProduct" :types="'1,2,3,4,5'" :status="1" :showPrice="true"
        :showAvailability="true" :periodRent="selectedPeriod.period.fullPeriod" :addProduct="addProduct">
        <div slot="start">
          <Select title="Informe o Grupo" field="groupRent" :required="false" v-model="itemsRent.groupRent"
            url="/api/v1/rental/group-rent/select-all" nameNewRegister="groupRentCreateUpdate" titleNewRegister="Grupo"
            :widthNewRegister="500" :heightNewRegister="250">
            <GroupRentCreateUpdate :registerInSelect="true" v-model="itemsRent.groupRent" />
          </Select>
        </div>
      </SearchProduct>
    </Modal>
  </div>
</template>

<script>

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ImportProductByRent from '../shared/import-product-rent/ImportProductByRent.vue'
import SearchProduct from "../../stock/search-product/SearchProduct.vue";
import SelectOptionPeriodRent from "../period-rental/SelectOptionPeriodRent.vue";
import GroupRentCreateUpdate from "../group-rent/GroupRentCreateUpdate.vue";

import ItemsRent from "./ItemsRent.js";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "ItemsRentCreate",
  components: {
    Modal,
    Button,
    Select,
    Alert,
    SearchProduct,
    SelectOptionPeriodRent,
    GroupRentCreateUpdate, ImportProductByRent
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      itemsRent: new ItemsRent(),
      selectedPeriod: {},
      urlCreate: "/api/v1/rental/items-rent/create",
      showSearchProduct: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapActions("generic", ["postApi"]),
    create() {
      this.openModal("addProduct");
      this.removeLoading(["btnItemsRentCreateUpdate"]);
      this.showSearchProduct = true;
    },
    addProduct(product) {
      this.updateClassItemsRent(product);
      let params = { url: this.urlCreate, obj: this.itemsRent };
      this.postApi(params).then((response) => {
        this.addEvent({
          name: "addedProduct",
          data: {
            showAll: false,
            period: { id: this.selectedPeriod.period.id },
          },
        });

        this.addEvent({ name: "updateTotalizationRent" });
        this.removeLoading([product.id]);
      });
    },
    hideSearchProduct() {
      this.showSearchProduct = false;
    },
    updateClassItemsRent(data) {
      this.itemsRent.periodRentId = this.selectedPeriod.period.id;
      this.itemsRent.productId = data.id;
      this.itemsRent.type = data.type;
      this.itemsRent.description = data.description;
      this.itemsRent.quantity = data.quantity;
      this.itemsRent.unitValue = data.value;
      this.itemsRent.unitCost = data.cost;
      this.itemsRent.replacementValue = data.replacementValue;
      this.itemsRent.percentageInsurance = data.percentageInsurance;
      this.itemsRent.calculateInsurance = data.calculateInsurance;
      this.itemsRent.statusRental = this.rent.statusRental;
      this.itemsRent.rentId = this.rent.id;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "btnBackTimeLineAvailability") {
          this.openModal("addProduct");
          this.removeLoading(["btnBackTimeLineAvailability"]);
        }
      },
      deep: true,
    },
  },
};
</script>
