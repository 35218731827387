<template>
  <div>
    <div>
      <Loading type="line" :center="false" v-show="loading" />
      <b-row>
        <b-col sm="12">
          <TableTotalRecords :totalRecords="content.totalRecords" />
        </b-col>
      </b-row>
    </div>
    <div class="table-items-nfse">
      <table class="table table-responsive-xs">
        <thead>
          <tr>
            <th>
              <span class="title-header">NÚMERO</span>
            </th>
            <th class="text-center">
              <span class="title-header">EMISSÃO</span>
            </th>
            <th>
              <span class="title-header">CLIENTE</span>
            </th>
            <th class="text-center">
              <span class="title-header">STATUS</span>
            </th>
            <th class="text-center">
              <span class="title-header">VALOR</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="item in content.data">
          <tr>
            <td>
              <span v-if="item.number">{{ item.number }}</span>
              <span v-else>Pendente</span>
            </td>
            <td class="text-center">
              {{ item.registeredDate | moment("DD/MM/YYYY HH:mm") }}
            </td>
            <td class="td-limit">{{ item.fullName }}</td>
            <td class="text-center">
              <div class="badge badge-warning td-margin" v-if="item.statusName == 'Em processamento'">
                Em processamento
              </div>
              <div class="badge badge-primary td-margin" v-if="item.statusName == 'Autorizada'">
                Autorizada
              </div>
              <div class="badge badge-success td-margin" v-if="item.statusName == 'Concluida'">
                Concluída
              </div>
              <div class="badge badge-danger td-margin" v-if="item.statusName == 'Rejeitada'">
                Rejeitada
              </div>
              <div class="badge badge-danger td-margin" v-if="item.statusName == 'Cancelada'">
                Cancelada
              </div>
            </td>
            <td class="text-center td-margin">{{ item.value | currency }}</td>
            <td class="text-right td-dropdown">
              <div class="side-by-side td-dropdown">
                <Dropdown title="Mais..." type="info" size="small" position="right" classIcon="fa-solid fa-chevron-down"
                  :param="item" :items="options" />
                <br />
                <div></div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="8">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CancelNfse />
    <PrintPreview v-if="print" module="TaxDocument" :listBillet="listBillet" :onHideModal="onHideModal" />
  </div>
</template>
<script>
import PrintPreview from '../../adm/document/PrintPreview.vue'

import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import ButtonSub from "@nixweb/nixloc-ui/src/component/forms/ButtonSub.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";

import CancelNfse from '../../../../views/modules/tax-document/CancelNfse.vue'

import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "ItemsLista",
  components: {
    Loading,
    ScrollBar,
    Dropdown,
    TableTotalRecords,
    ButtonSub,
    CancelNfse, PrintPreview
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      urlGetApi: "/api/v1/tax-document/nfse/get-all",
      urlGetPdf: "/api/v1/tax-document/nfse/get-pdf",
      data: [],
      loading: true,
      listBillet: [],
      print: false,
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
      options: [
        {
          title: "Baixar PDF",
          classIcon: "fa-solid fa-file-pdf",
          eventName: "getPdfNfse",
        },
        {
          title: "Cancelar",
          classIcon: "fa-solid fa-ban",
          eventName: "cancelNfse",
          style: "color:red;",
        },
      ],
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapState("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["getApi", "getFileApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          any: this.rent.id,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    getPdf(idIntegracao) {
      let params = {
        url: this.urlGetPdf,
        obj: {
          idIntegracao: idIntegracao,
          fileName: `nfse`,
          fileType: "pdf"
        },
      };
      this.getFileApi(params).then(() => { });
    },
    download(url) {
      var link = document.createElement('a');
      link.href = url;
      link.target = "_blank";
      link.download = url.substring(url.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onHideModal() {
      this.print = false;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "getPdfNfse") {
          this.download(event.data.urlPdf);
        }

        if (event.name == "printBilling") {
          this.print = true;
          this.invoiceId = event.data.id;
          this.listBillet = event.data.checkBilletResponse;
        }

        if (event.name == "canceledNfse") this.getAll();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-dropdown {
  overflow: visible;
}

.td-margin {
  margin-top: 3px;
}

.td-limit {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-items-nfse {
  min-height: 300px;
}
</style>
