<template>
  <div>
    <b-row>
      <b-col sm="9">
        <Select title="Tipo de Pagamento" field="typePayment" :required="true" :markFormDirty="false"
          :propsParams="{ any: 'Rental' }" v-model="paymentRent.typePayment" url="/api/v1/finance/type-payment/select-all"
          :showNewRegister="hasRule('Rental')" nameNewRegister="typePaymentCreateUpdate"
          titleNewRegister="Tipo de Pagamento" :widthNewRegister="500" :heightNewRegister="250">
          <TypePaymentCreateUpdate :registerInSelect="true" v-model="paymentRent.typePayment" />
        </Select>
      </b-col>
      <b-col sm="3">
        <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" type="float" v-model="paymentRent.value" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
          :required="true" v-model="paymentRent.dueDate" />
      </b-col>
      <b-col sm="9">
        <InputText title="Descrição" field="name" :formName="formName" :required="false" :maxLength="50"
          v-model="paymentRent.description" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSavePaymentRent" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="savePaymentRent" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import PaymentRent from "@/components/modules/rental/payment-rent/PaymentRent.js";
import TypePaymentCreateUpdate from "../../finance/type-payment/TypePaymentCreateUpdate.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PaymentRentCreateUpdate",
  components: {
    InputText,
    InputDecimal,
    DateTime,
    Select,
    Button,
    CheckboxGroup,
    TypePaymentCreateUpdate,
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      formName: "paymentRentCreateUpdate",
      paymentRent: new PaymentRent(),
      urlCreate: "/api/v1/rental/payment-rent/create",
      urlUpdate: "/api/v1/rental/payment-rent/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    savePaymentRent() {
      this.paymentRent.rentId = this.rentId;

      if (this.paymentRent.id) {
        let params = { url: this.urlUpdate, obj: this.paymentRent };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSavePaymentRent"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.paymentRent };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSavePaymentRent"]);
        });
      }
    },
    update(data) {
      this.paymentRent = new PaymentRent();
      this.paymentRent.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "paymentRentCreate") {
          this.resetValidation(this.formName);
          this.paymentRent = new PaymentRent();
        }
        if (event.name == "paymentRentUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
