<template>
  <div>
    <div class="div-accessory">
      <Molded>
        <ItemsAccessoryCreate :rent="rent" :itemsRentCurrent="itemsRent" />
      </Molded>
    </div>
    <Loading type="line" :center="false" v-show="loading" />
    <TableTotalRecords :totalRecords="content.totalRecords" />
    <ScrollBar id="items-rent" :minHeight="300" :maxHeight="300">
      <table class="table table-responsive-xs" v-show="!showTableDraggable">
        <thead>
          <tr>
            <th class="td-checkbox">
              <i v-if="content.data.length > 1" class="fa-regular fa-maximize icon-order icon-cursor"
                @click="changeItemToTableDraggable(content.data)">
              </i>
              <i v-else class="fa-solid fa-layer-group icon-order"></i>
              <span class="title-header"> Produto </span>
            </th>
            <th class="text-center">
              <span class="title-header">Quantidade</span>
            </th>
            <th class="text-center">
              <span class="title-header">Status</span>
            </th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody v-for="item in content.data">
          <td class="td-product td" @click="update(item)">
            <span>
              {{ item.productFullName }}
            </span>
            <br />
            <span class="description" v-html="item.description"> </span>
          </td>
          <td class="text-center td-quantity">
            <div>
              <span> {{ item.quantity }}</span>
              <span v-if="item.type != 3">
                ({{ item.unitOfMeasurementName }})
              </span>
            </div>
          </td>
          <td class="text-center">
            <div class="quantity" @click="totalizationAndHistory(item)">
              <ItemsRentStatusStock v-if="item.type != 3" :data="item" />
            </div>
          </td>
          <td class="text-center">
            <Button :_key="item.id + 'a'" type="danger" classIcon="fa-solid fa-trash" size="small" :params="item"
              :clicked="remove" />
          </td>
        </tbody>
        <tbody v-show="content.totalRecords == 0">
          <tr>
            <td colspan="12">
              <span class="title">Nenhum registro encontrado!</span>
            </td>
          </tr>
        </tbody>
      </table>
      <TableDraggable urlOrderAllApi="/api/v1/rental/items-rent/order-all" v-if="showTableDraggable"
        :data="dataDraggable" :propsParam="{
          any: orderByDraggable,
          itemsRentId: itemsRent.id,
          rentId: rent.id,
        }" />
    </ScrollBar>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import ItemsAccessoryCreate from "./ItemsAccessoryCreate.vue";
import ItemsRentStatusStock from "./ItemsRentStatusStock.vue";
import ItemRentStockAvailability from "./ItemRentStockAvailability.vue";
import TableDraggable from "@nixweb/nixloc-ui/src/component/shared/TableDraggable.vue";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsAccessoryList",
  props: {
    rent: Object,
  },
  components: {
    Button,
    Loading,
    ScrollBar,
    Molded,
    ItemsRentStatusStock,
    ItemRentStockAvailability,
    TableDraggable,
    TableTotalRecords,
    ItemsAccessoryCreate,
  },
  computed: {
    ...mapState("generic", ["event"]),
  },
  data() {
    return {
      urlGetApi: "/api/v1/rental/items-rent/get-all-by-accessory",
      urlDelete: "/api/v1/rental/items-rent/delete",
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
      itemsRent: {},
      dataDraggable: [],
      orderByDraggable: "",
      showTableDraggable: false,
      loading: true,
    };
  },
  methods: {
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          itemsRentId: this.itemsRent.id,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    remove(item) {
      let params = {
        url: this.urlDelete,
        selected: [item.id],
      };

      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.getAll();
          this.addEvent({ name: "removedAccessory" });
        }
      });
      this.removeLoading([item.id + "a"]);
    },
    changeItemToTableDraggable(data) {
      this.orderByDraggable = "item";
      this.dataDraggable = [];
      this.showTableDraggable = true;
      data.forEach((item) => {
        let obj = { id: item.id, name: item.productFullName };
        this.dataDraggable.push(obj);
      });
    },
    update(item) {
      this.addEvent({ name: "updateAccessory", data: item });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "getAllAccessory") {
          this.content.data = [];
          this.itemsRent = event.data.itemsRent;
          this.getAll();
        }

        if (event.name == "confirmedTableDraggable") {
          this.showTableDraggable = false;
          this.getAll();
        }

        if (event.name == "cancelTableDraggable")
          this.showTableDraggable = false;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.div-accessory {
  margin-top: 8px;
  margin-bottom: 8px;
}

.name-item {
  font-size: 16px;
  margin-bottom: 8px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-product {
  width: 500px;
  background-color: #f1f4f9;
  cursor: pointer;
}

.td-quantity {
  width: 150px;
}

.quantity {
  cursor: pointer;
}

.quantity:hover {
  text-decoration: underline;
}

.description {
  font-weight: 400;
  color: grey;
}

.icon-cursor {
  cursor: pointer;
}

.icon-order-group {
  color: #779cb3;
}

.text-order {
  font-size: 13px;
  margin-left: 10px;
  font-style: italic;
}

.td-checkbox {
  width: 30px;
}
</style>
