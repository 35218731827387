export default class PaymentRent {
    constructor() {
        this.id = "";
        this.typePayment = { id: "", content: "" };
        this.dueDate = "";
        this.value = 0;
        this.description = "";
    }
    update(data) {
        this.id = data.id;
        this.typePayment = { id: data.typePaymentId, content: data.typePaymentName };
        this.dueDate = data.dueDate;
        this.value = data.value;
        this.description = data.description;
    }
}