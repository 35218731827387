<template>
    <div class="recurrence-main">
        <div class="title-automation">
            <i class="fa-regular fa-robot"></i> O que o Robô irá fazer?
        </div>
        <div class="div-automation">
            <AutomationBuilder :rules="rules" />
        </div>
    </div>
</template>
<script>

import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import AutomationBuilder from "@nixweb/nixloc-ui/src/component/shared/automation/AutomationBuilder";


export default {
    name: "AutomationRentLong",
    props: {
        formName: String,
        rules: Array,
    },
    components: {
        SelectStatic,
        DateTime,
        InputDecimal,
        AutomationBuilder
    },
    data() {
        return {
            frequency: "",
            dateStart: "",
            dateEnd: "",
            value: 0
        }
    }
}
</script>
<style scoped>
.recurrence-main {
    margin-top: 10px;
}

.title-automation {
    font-size: 15px;
    margin-bottom: 10px;
}

.div-automation {
    min-height: 100px;
}
</style>