<template>
  <div>
    <div class="badge badge-warning" v-if="data.statusInvoiceItemsRent == 1">
      Não Faturado
    </div>
    <div class="badge badge-success" v-if="data.statusInvoiceItemsRent == 2">
      Faturado
    </div>
     <div class="badge badge-info" v-if="data.statusInvoiceItemsRent == 3">
      Não Fatura
    </div>
     <div class="badge badge-danger" v-if="data.statusInvoiceItemsRent == 4">
      Cancelado
    </div>
    <br />
  </div>
</template>
<script>
export default {
  name: "ItemsRentalTotalization",
  props: {
    data: Object,
  },
};
</script>
<style scoped>
.badge {
  font-size: 12px !important;
  margin-left: 5px;
}

.unit {
  font-size: 13px;
}

.total {
  font-weight: 600;
  font-size: 15px;
}

.discount {
  font-size: 12px !important;
  color: red;
}
</style>
