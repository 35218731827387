<template>
    <div>
        <div v-show="!loading" class="text-center">
            <Button _key="btnDownload" title="Baixar Original" type="info" size="medium"
                :params="{ url: status.original_file }" :clicked="download" />
            <Button v-if="status.status == 'signed'" _key="btnDownload" title="Baixar Assinado" type="success" size="medium"
                :params="{ url: status.signed_file }" :clicked="download" />
        </div>
        <br>
        <Loading :center="false" v-show="loading" />
        <ScrollBar v-show="!loading" :minHeight="330" :maxHeight="330">
            <div v-for="signer in content.data">
                <div class="div-signer">
                    <div>
                        <span v-if="signer.name">
                            {{ signer.name }}
                        </span>
                        <span v-if="signer.email">
                            - {{ signer.email }}
                        </span>
                        <span v-if="signer.phoneNumber">
                            - {{ signer.phoneNumber }}
                        </span>
                    </div>
                    <div>
                        <span>{{ signer.signUrl }}</span>
                        <div @click="copied = signer.signUrl" class="div-clipboard" v-clipboard="signer.signUrl">
                            <span v-if="!copied"> <i class="fa-solid fa-link"></i> Copiar</span>
                            <span class="copied" v-else><i class="fa-solid fa-link"></i> Copiado</span>
                        </div>
                    </div>
                    <div class="div-signer-status">
                        <div class="badge badge-warning" v-if="signer.statusName == 'Pendente'">
                            {{ signer.statusName }}
                        </div>
                        <div class="badge badge-success" v-if="signer.statusName == 'Assinado'">
                            {{ signer.statusName }}
                        </div>
                        <div class="badge badge-info" v-if="signer.statusName == 'Aberto'">
                            {{ signer.statusName }}
                        </div>
                        <div class="badge badge-danger" v-if="signer.statusName == 'Recusado'">
                            {{ signer.statusName }}
                        </div>
                    </div>
                </div>
            </div>
        </ScrollBar>
    </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "SignerList",
    components: {
        Loading,
        ScrollBar,
        Button
    },
    data() {
        return {
            urlGet: "/api/v1/signer/get-all",
            urlGetStatus: "/api/v1/signature-file/get-detail",
            copied: "",
            item: {},
            content: {},
            status: {},
            loading: false,
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getAll() {
            let params = {
                url: this.urlGet,
                obj: {
                    any: this.item.id,
                },
            };
            this.getApi(params).then((response) => {
                this.content = response.content;
            });
        },
        getStatus() {
            let params = {
                url: this.urlGetStatus,
                obj: {
                    any: this.item.token,
                },
            };
            this.getApi(params).then((response) => {
                console.log(response);
                this.status = response.content;
                this.loading = false;
            });
        },
        download(params) {
            const downloadLink = document.createElement("a");
            downloadLink.href = params.url;
            downloadLink.target = "_blank";
            downloadLink.click();
            this.removeLoading(['btnDownload']);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "openSigners") {
                    this.copied = "";
                    this.loading = true;
                    this.item = event.data;
                    this.getAll();
                    this.getStatus();
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.div-signer {
    margin-left: 50px;
    margin-right: 50px;
    border: 1px solid #E5E4E8;
    border-radius: 10px;
    background-color: #FAFAFC;
    padding-left: 10px;
    padding-top: 5px;
}

.div-signer-status {
    margin-top: 10px;
}

.div-clipboard {
    cursor: pointer;
}
</style>