var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('Select',{attrs:{"title":"Tipo","field":"type","formName":_vm.formName,"required":true,"markFormDirty":false,"url":"/api/v1/stock/type-product/select-all","propsParams":{ any: 2 },"disabled":_vm.statusStock == 3 ||
            _vm.statusStock == 4 ||
            _vm.statusStock == 5 ||
            _vm.statusStock == 6,"showNewRegister":false,"changed":_vm.cleanProduct},model:{value:(_vm.itemsRent.typeProduct),callback:function ($$v) {_vm.$set(_vm.itemsRent, "typeProduct", $$v)},expression:"itemsRent.typeProduct"}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('Select',{attrs:{"title":"Acessório","field":"product","formName":_vm.formName,"required":true,"markFormDirty":false,"url":"/api/v1/stock/product/select-all","propsParams":{ any: _vm.itemsRent.typeProduct.id },"disabled":!_vm.itemsRent.typeProduct.id ||
            _vm.statusStock == 3 ||
            _vm.statusStock == 4 ||
            _vm.statusStock == 5 ||
            _vm.statusStock == 6,"showNewRegister":false},model:{value:(_vm.itemsRent.product),callback:function ($$v) {_vm.$set(_vm.itemsRent, "product", $$v)},expression:"itemsRent.product"}})],1),_c('b-col',{attrs:{"sm":"2"}},[_c('InputNumber',{attrs:{"title":"Qtd","field":"quantity","formName":_vm.formName,"required":true,"markFormDirty":false,"maxLength":5,"disabled":_vm.statusStock == 5 || _vm.statusStock == 6,"type":"float"},model:{value:(_vm.itemsRent.quantity),callback:function ($$v) {_vm.$set(_vm.itemsRent, "quantity", $$v)},expression:"itemsRent.quantity"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"10"}},[_c('InputText',{attrs:{"title":"Descrição","field":"identification","formName":_vm.formName,"required":false,"maxLength":500},model:{value:(_vm.itemsRent.description),callback:function ($$v) {_vm.$set(_vm.itemsRent, "description", $$v)},expression:"itemsRent.description"}})],1),_c('b-col',{attrs:{"sm":"2"}},[_c('div',{staticClass:"div-btn text-right"},[_c('Button',{attrs:{"_key":"btnAddAccessory","type":_vm.itemsRent.id ? 'success' : 'primary',"disabled":!_vm.isFormValid(_vm.formName),"classIcon":_vm.itemsRent.id ? 'fa-solid fa-check' : 'fa-regular fa-plus-large',"size":"small","clicked":_vm.save}}),(_vm.itemsRent.id)?_c('Button',{attrs:{"_key":"btnCancelAccessory","type":"danger","classIcon":"fa-solid fa-xmark","size":"small","clicked":_vm.cancel}}):_vm._e()],1)])],1),_c('br')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }