<template>
  <div>
    <div>
      <b-row>
        <b-col sm="4">
          <Select
            title="Tipo"
            field="type"
            :formName="formName"
            :required="true"
            :markFormDirty="false"
            url="/api/v1/stock/type-product/select-all"
            :propsParams="{ any: 2 }"
            :disabled="
              statusStock == 3 ||
              statusStock == 4 ||
              statusStock == 5 ||
              statusStock == 6
            "
            :showNewRegister="false"
            v-model="itemsRent.typeProduct"
            :changed="cleanProduct"
          />
        </b-col>
        <b-col sm="6">
          <Select
            title="Acessório"
            field="product"
            :formName="formName"
            :required="true"
            :markFormDirty="false"
            url="/api/v1/stock/product/select-all"
            :propsParams="{ any: itemsRent.typeProduct.id }"
            :disabled="
              !itemsRent.typeProduct.id ||
              statusStock == 3 ||
              statusStock == 4 ||
              statusStock == 5 ||
              statusStock == 6
            "
            :showNewRegister="false"
            v-model="itemsRent.product"
          />
        </b-col>
        <b-col sm="2">
          <InputNumber
            title="Qtd"
            field="quantity"
            :formName="formName"
            :required="true"
            :markFormDirty="false"
            :maxLength="5"
            :disabled="statusStock == 5 || statusStock == 6"
            type="float"
            v-model="itemsRent.quantity"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="10">
          <InputText
            title="Descrição"
            field="identification"
            :formName="formName"
            :required="false"
            :maxLength="500"
            v-model="itemsRent.description"
          />
        </b-col>
        <b-col sm="2">
          <div class="div-btn text-right">
            <Button
              _key="btnAddAccessory"
              :type="itemsRent.id ? 'success' : 'primary'"
              :disabled="!isFormValid(formName)"
              :classIcon="
                itemsRent.id ? 'fa-solid fa-check' : 'fa-regular fa-plus-large'
              "
              size="small"
              :clicked="save"
            />
            <Button
              _key="btnCancelAccessory"
              v-if="itemsRent.id"
              type="danger"
              classIcon="fa-solid fa-xmark"
              size="small"
              :clicked="cancel"
            />
          </div>
        </b-col>
      </b-row>
      <br />
    </div>
  </div>
</template>

<script>
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import ItemsRent from "@/components/modules/rental/items-rent/ItemsRent.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsAccessoryCreate",
  components: {
    InputNumber,
    InputText,
    Select,
    Button,
    Loading,
  },
  props: {
    itemsRentCurrent: Object,
    rent: Object,
  },
  data() {
    return {
      formName: "itemsAccessoryCreateUpdate",
      itemsRent: new ItemsRent(),
      urlCreate: "/api/v1/rental/items-rent/create-accessory",
      urlUpdate: "/api/v1/rental/items-rent/update-accessory",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    statusStock() {
      return this.itemsRent.statusStockItemsRent;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    save() {
      this.itemsRent.rentId = this.itemsRentCurrent.rentId;
      this.itemsRent.periodRentId = this.itemsRentCurrent.periodRentId;
      this.itemsRent.itemsRentId = this.itemsRentCurrent.id;
      this.itemsRent.productId = this.itemsRent.product.id;
      this.itemsRent.statusRental = this.rent.statusRental;

      if (this.itemsRent.id) {
        let params = { url: this.urlUpdate, obj: this.itemsRent };
        this.putApi(params).then((response) => {
          this.addEvent({
            name: "getAllAccessory",
            data: { itemsRent: this.itemsRentCurrent },
          });
          this.removeLoading(["btnAddAccessory"]);
          this.cancel();
        });
      } else {
        let params = { url: this.urlCreate, obj: this.itemsRent };
        this.postApi(params).then((response) => {
          this.addEvent({
            name: "getAllAccessory",
            data: { itemsRent: this.itemsRentCurrent },
          });
          this.removeLoading(["btnAddAccessory"]);
          this.cancel();
        });
      }
    },
    cleanProduct() {
      this.itemsRent.product = { id: "", content: "" };
    },
    update(data) {
      this.itemsRent = new ItemsRent();
      this.itemsRent.update(data);
    },
    cancel() {
      this.itemsRent = new ItemsRent();
      this.resetValidation(this.formName);
      this.removeLoading(["btnCancelAccessory"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateAccessory") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-btn {
  margin-top: 38px;
  margin-right: 10px;
}
</style>