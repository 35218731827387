<template>
  <div>
    <ViewTemplateConfiguration
      :templateList="templateList"
      :showSearch="false"
      :showHorizontalFilter="false"
      :propsParam="propsParam"
    >
      <div slot="content-buttons">
        <Button
          _key="btnPeriodRentCreateUpdate"
          title="Período"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations } from "vuex";

export default {
  name: "PeriodRentList",
  components: {
    ViewTemplateConfiguration,
    Button,
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/rental/period-rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/period-rent/delete",
        headerTable: [
          {
            field: "fullPeriod",
            title: "Período",
            type: "period-rent",
            eventName: "periodRentUpdate",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
          },
        ],
      },
      propsParam: {
        rentId: this.rentId,
        periodRentId: "null",
      },
    };
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    create() {
      this.addEvent({ name: "periodRentCreateUpdate" });
      this.removeLoading(["btnPeriodRentCreateUpdate"]);
    },
  },
};
</script>
