<template>
  <div>
    <div v-show="!showTableDraggable">
      <ItemsRentCreate :rent="rent" />
      <br />
      <HorizontalFilter :showTotalPerPage="false">
        <div class="fixed-filter-options div-filter" slot="content-filter-horizontal">
          <Toggle title="Mostrar produto(s) somente estoque" :color="'#4BB4E2'" v-model="withOnlyStock" />
          <Toggle title="Mostrar acessório(s) vinculado(s)" :color="'#4BB4E2'" v-model="withAccessory" />
        </div>
      </HorizontalFilter>
      <Loading type="line" :center="false" v-show="loading" />
      <b-row>
        <b-col sm="6">
          <b-form-checkbox v-model="showSelectAll" @change="select" v-show="content.totalRecords > 0">
            TODOS PRODUTOS
          </b-form-checkbox>
        </b-col>
        <b-col sm="6">
          <TableTotalRecords :totalRecords="content.totalRecords" />
        </b-col>
      </b-row>
    </div>
    <ItemRentFixedBar :selected="selected" :rent="rent" />

    <table class="table table-responsive-xs" v-show="!showTableDraggable">
      <thead>
        <tr>
          <th class="td-checkbox">
            <i class="fa-regular fa-maximize icon-order-group icon-cursor"
              @click="changeGroupToTableDraggable(grouped)">
            </i>
          </th>
          <th>
            <span class="title-header"> Produto </span>
          </th>
          <th class="text-center">
            <span class="title-header">Quantidade</span>
          </th>
          <th class="text-center">
            <span class="title-header">Estoque</span>
          </th>
          <th class="text-center">
            <span class="title-header">Fatura</span>
          </th>
          <th class="text-center">
            <span class="title-header">Valor</span>
          </th>
          <th class="text-center">
            <span class="title-header">ACESS..</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(data, groupName) in grouped">
        <td class="group" colspan="8">
          <div>
            <i v-if="data.length > 1" class="fa-regular fa-maximize icon-order icon-cursor"
              @click="changeItemToTableDraggable(data)">
            </i>
            <i v-else class="fa-solid fa-layer-group icon-order"></i>
            <span v-if="groupName" class="title-group">
              {{ groupName }}
            </span>
            <span v-else class="title-group">
              <span class="without-group">Sem grupo</span>
            </span>
          </div>
        </td>
        <tr v-for="item in data">
          <td class="td-checkbox">
            <b-form-checkbox v-model="selected" :value="item.id" />
          </td>
          <td class="td-product td" @click="update(item)">
            <span>
              {{ item.productFullName }}
            </span>
            <br />
            <span class="description" v-html="item.description"> </span>
          </td>
          <td class="text-center td-quantity td">
            <div>
              <span> {{ item.quantity }}</span>
              <span v-if="item.type != 3">
                ({{ item.unitOfMeasurementName }})
              </span>
            </div>
            <div class="quantity" @click="totalizationAndHistory(item)">
              <ItemsRentStatusStock :data="item" :rent="rent" />
            </div>
          </td>
          <td class="text-center td">
            <ItemRentStockAvailability v-if="item.type != 3" :data="item.available" :item="item"
              :showButtonBack="false" />
            <i class="icon-only-stock fa-solid fa-grid-2-plus" v-if="item.onlyStock"></i>
          </td>
          <td class="text-center td">
            <ItemsRentalInvoice :data="item" />
          </td>
          <td class="td-price text-center td">
            <ItemsRentalTotalization :data="item" />
          </td>
          <td class="text-center td">
            <div class="div-accessory" v-if="item.type == 1">
              <Button :_key="item.id" color="white" backGroundColor="#779CBA" :title="item.totalAccessory.toString()"
                size="small" :params="{ itemsRent: item }" :clicked="openModalAccessory" />
            </div>
            <div v-if="item.type == 2"><i class="fas fa-toolbox"></i></div>
            <div v-if="item.type == 3">
              <i class="fas fa-people-carry"></i>
            </div>
            <div v-if="item.type == 5">
              <i class="fa-solid fa-recycle"></i>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-show="content.totalRecords == 0">
        <tr>
          <td colspan="12">
            <span class="title">Nenhum registro encontrado!</span>
          </td>
        </tr>
      </tbody>
    </table>
    <TableDraggable urlOrderAllApi="/api/v1/rental/items-rent/order-all" v-if="showTableDraggable" :data="dataDraggable"
      :propsParam="{
        any: orderByDraggable,
        rentId: rent.id,
        periodRentid: periodRentId,
      }" />

    <Modal :title="modalOptions.title" :width="modalOptions.width" :onHideModal="onHideModal"
      v-show="showModal('optionsItemsRent')">
      <div v-show="modalOptions.name == 'confirmRemove'">
        <Alert type="warning">
          <span>
            Todas as movimentações relacionadas ao produto serão removidas. Esta
            ação é irreversível!
          </span>
        </Alert>

        <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="removeSelected" />
      </div>
      <div v-if="modalOptions.name == 'accessory'">
        <ItemsAccessoryList :rent="rent" />
      </div>
      <div v-show="modalOptions.name == 'totalizationAndHistory'">
        <b-tabs>
          <b-tab title="Resumo" v-if="item.type == 1 || item.type == 2">
            <TotalizationItemsMoviment />
          </b-tab>
          <b-tab title="Patrimônio" v-if="!item.allowWithoutPatrimony">
            <PatrimonyMoviment />
          </b-tab>
          <b-tab title="Histórico">
            <HistoryMoviment />
          </b-tab>
        </b-tabs>
      </div>
      <ItemRentUpdate v-show="modalOptions.name == 'itemRentUpdate'" />
    </Modal>
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import TableDraggable from "@nixweb/nixloc-ui/src/component/shared/TableDraggable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import HorizontalFilter from "@nixweb/nixloc-ui/src/component/shared/HorizontalFilter.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import ItemRentFixedBar from "./ItemRentFixedBar.vue";
import ItemsRentStatusStock from "./ItemsRentStatusStock.vue";
import ItemsRentalTotalization from "./ItemsRentalTotalization.vue";
import ItemsRentalInvoice from "./ItemsRentalInvoice.vue";
import ItemsRentCreate from "./ItemsRentCreate.vue";
import ItemRentUpdate from "./ItemRentUpdate.vue";
import ItemRentStockAvailability from "./ItemRentStockAvailability.vue";
import PatrimonyMoviment from "../../stock/items-moviment/PatrimonyMoviment.vue";
import TotalizationItemsMoviment from "../../stock/moviment/TotalizationItemsMoviment.vue";
import HistoryMoviment from "../../stock/history-moviment/HistoryMoviment.vue";
import ItemsAccessoryList from "./ItemsAccessoryList.vue";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsList",
  components: {
    ItemsRentCreate,
    Loading,
    ScrollBar,
    TableTotalRecords,
    TableDraggable,
    ItemsRentalTotalization,
    HorizontalFilter,
    Toggle,
    Confirmation,
    ItemRentFixedBar,
    Modal,
    Alert,
    Button,
    ItemRentUpdate,
    ItemsRentalInvoice,
    ItemsRentStatusStock,
    ItemRentStockAvailability,
    TotalizationItemsMoviment,
    PatrimonyMoviment,
    HistoryMoviment,
    ItemsAccessoryList,
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      urlGetApi: "/api/v1/rental/items-rent/get-all",
      urlDelete: "/api/v1/rental/items-rent/delete",
      data: [],
      periodRentId: "",
      item: {},
      loading: false,
      content: {
        data: [],
        totalRecords: 0,
      },
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 20,
      },
      selected: [],
      showSelectAll: false,
      dataDraggable: [],
      orderByDraggable: "",
      showTableDraggable: false,
      withOnlyStock: false,
      withAccessory: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["groupBy", "showModal"]),
    ...mapState("user", ["userLogged"]),
    ...mapState("generic", ["event"]),
    grouped() {
      var list = this.groupBy({
        array: this.content.data,
        key: "groupRentName",
      });
      return list;
    },
    allowOutputGreater() {
      return this.userLogged.parameterStock.allowOutputGreater;
    },
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          rentId: this.rent.id,
          periodRentId: this.periodRentId,
          withOnlyStock: this.withOnlyStock,
          withAccessory: this.withAccessory,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    update(item) {
      this.modalOptions = {
        name: "itemRentUpdate",
        title: "Produto",
        width: 1000,
      };
      this.openModal("optionsItemsRent");
      this.addEvent({ name: "itemRentUpdate", data: item });
    },
    totalizationAndHistory(item) {
      this.modalOptions = {
        name: "totalizationAndHistory",
        title: item.productFullName,
        width: 830,
      };
      this.item = item;
      this.addEvent({ name: "totalizationAndHistory", data: item });
      this.openModal("optionsItemsRent");
    },
    confirmRemove() {
      this.removeLoading(["btnRemoveItem"]);
    },
    removeSelected() {
      let params = {
        url: this.urlDelete,
        selected: this.selected,
      };

      this.deleteAllApi(params).then((reponse) => {
        if (reponse.success) {
          this.reset({ showAll: false, period: { id: this.periodRentId } });
          this.hideModal();
        }

        this.addEvent({ name: "updateTotalizationRent" });
        this.addEvent({ name: "removedItemRent" });
        this.removeLoading(["confirm"]);
      });
    },
    reset(data) {
      this.periodRentId = data.showAll ? null : data.period.id;
      this.selected = [];
      this.showSelectAll = false;
      this.baseParams.currentPage = 1;
      this.dataDraggable = [];
      this.showTableDraggable = false;
      this.getAll();
    },
    endScrollBar() {
      if (this.content.data.length != this.content.totalRecords) {
        this.baseParams.currentPage++;
        this.getAll();
      }
    },
    select() {
      this.selected = [];
      if (this.showSelectAll) {
        for (let i in this.content.data) {
          this.selected.push(this.content.data[i].id);
        }
      }
    },
    changeItemToTableDraggable(data) {
      this.orderByDraggable = "item";
      this.dataDraggable = [];
      this.showTableDraggable = true;
      data.forEach((item) => {
        let obj = { id: item.id, name: item.productFullName };
        this.dataDraggable.push(obj);
      });
    },
    changeGroupToTableDraggable(data) {
      this.orderByDraggable = "group";
      this.dataDraggable = [];
      var group = Object.entries(data);
      group.forEach((item) => {
        let obj = { id: item[1][0].groupRentId, name: item[0] };
        this.dataDraggable.push(obj);
      });
      this.showTableDraggable = true;
    },
    isShowTableSub(itemId) {
      return this.itemsAccessory.ids.includes(itemId);
    },
    openModalAccessory(params) {
      this.modalOptions = {
        name: "accessory",
        title: "Acessório(s)",
        width: 1100,
      };

      this.openModal("optionsItemsRent");

      this.removeLoading([params.itemsRent.id]);
      this.addEvent({ name: "getAllAccessory", data: params });
    },
    onHideModal() {
      this.getAll();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "closedFixedBar") {
          this.selected = [];
          this.showSelectAll = false;
        }

        if (event.name == "removeItemFixedBar") {
          this.modalOptions = {
            name: "confirmRemove",
            title: "Tem certeza que deseja excluir?",
            width: 500,
          };
          this.openModal("optionsItemsRent");
          this.confirmRemove();
        }

        if (event.name == "selectedPeriodRent" || event.name == "addedProduct") {
          this.reset(event.data);

        }


        if (event.name == "updateItemsRent" || event.name == "itemsImported")
          this.getAll();

        if (event.name == "updateFixedBar") {
          this.selected = [];
          this.showSelectAll = false;
          this.getAll();
        }

        if (event.name == "cancelTableDraggable")
          this.showTableDraggable = false;

        if (event.name == "confirmedTableDraggable")
          this.reset({ showAll: false, period: { id: this.periodRentId } });

        if (
          event.name == "rentAccepted" ||
          event.name == "rentProcessed" ||
          event.name == "rentRejected"
        )
          this.reset({ showAll: false, period: { id: this.periodRentId } });
      },
      deep: true,
    },
    withOnlyStock: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    withAccessory: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.div-accessory {
  margin-top: 8px;
}

.group {
  background-color: #fafafc;
  font-size: 14px;
  font-weight: 400;
}

.div-filter {
  margin-bottom: 30px;
  padding: 10px;
}

.with-only-stock {
  cursor: pointer;
  margin-left: 20px;
  color: #757d8c;
}

.icon-only-stock {
  color: #757d8c;
}

.title-group {
  font-size: 14px;
  margin-left: 10px;
}

.name-item {
  font-size: 16px;
  margin-bottom: 8px;
}

.div-warning {
  margin-bottom: 20px;
}

.td-product {
  width: 500px;
  background-color: #f1f4f9;
  cursor: pointer;
}

.td-quantity {
  width: 150px;
}

.td-price {
  min-width: 300px;
}

.quantity {
  cursor: pointer;
}

.quantity:hover {
  text-decoration: underline;
}

.description {
  font-weight: 400;
  color: grey;
}

.without-group {
  font-size: 13px;
  font-style: italic;
  color: grey;
}

.icon-cursor {
  cursor: pointer;
}

.icon-order-group {
  color: #779cb3;
}

.text-order {
  font-size: 13px;
  margin-left: 10px;
  font-style: italic;
}

.td-checkbox {
  width: 30px;
}
</style>
