<template>
  <div>
    <div class="text-center">
      <Button _key="btnRentAccept" title="SIM, aprovar" classIcon="fa-solid fa-circle-check" type="success" size="small"
        :clicked="update" />
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";

import { mapMutations, mapActions } from "vuex";

export default {
  name: "RentAccept",
  props: {
    rentId: String,
  },
  components: {
    Button,
  },
  data() {
    return { urlUpdate: "/api/v1/rental/rent/accept" };
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
    update() {
      let params = { url: this.urlUpdate, obj: { rentId: this.rentId } };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "rentAccepted" });
          this.hideModal();
        }
        this.removeLoading(["btnRentAccept"]);
      });
    },
  },
};
</script>