<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="optionsConditional" />
    <Modal :title="modalOptions.title" :width="modalOptions.width" v-if="showModal('optionsRent')"
      :onHideModal="closeModal">
      <RentAccept :rentId="rent.id" v-if="modalOptions.name == 'acceptRent'" />
      <RentProgress :rentId="rent.id" v-if="modalOptions.name == 'progressRent'" />
      <RentReject :rentId="rent.id" v-if="modalOptions.name == 'rejectRent'" />
      <CloneRent :rent="rent" v-if="modalOptions.name == 'cloneRent'" />
      <div class="select-document" v-show="modalOptions.name == 'selectDocument'">
      </div>
    </Modal>
    <PrintPreview v-if="printRent" module="Rental" :rentId="rent.id" :periodRentId="periodRent.id"
      :onHideModal="closeModal">

      <Select title="Período de Locação (opcional)" v-if="totalRecordsPeriod > 1" field="type" :required="false"
        :propsParams="{ any: rent.id }" :showNewRegister="false" url="/api/v1/rental/period-rent/select-all"
        v-model="periodRent">
      </Select>
    </PrintPreview>
  </div>
</template>
<script>


import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import CloneRent from '../clone-rent/CloneRent.vue'
import RentAccept from "../rent/RentAccept.vue";
import RentProgress from "../rent/RentProgress.vue";
import RentReject from "../rent/RentReject.vue";
import PrintPreview from '../../adm/document/PrintPreview.vue'

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "DropdownOptionsRent",
  props: {
    rent: Object,
    statusMovimentStock: Object,
    totalProductForInvoice: Number,
  },
  components: {
    Dropdown,
    Modal,
    Select,
    RentAccept,
    RentReject,
    RentProgress,
    PrintPreview,
    CloneRent
  },
  data() {
    return {
      documentId: "",
      periodRent: {},
      totalRecordsPeriod: 0,
      printRent: false,
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      options: [
        {
          title: "Aprovar",
          showByStatus: [1, 3],
          classIcon: "fa-solid fa-circle-check",
          eventName: "acceptRent",
          style: "color:#38A169;",
        },
        {
          title: "Orçamento",
          showByStatus: [2, 3],
          classIcon: "fa-sharp fa-solid fa-file-invoice",
          eventName: "progressRent",
          style: "color:#4BB4E2;",
        },
        {
          title: "Reprovar",
          showByStatus: [1, 2],
          classIcon: "fa-solid fa-do-not-enter",
          eventName: "rejectRent",
          style: "color:red;",
          hr: true,
        },
        {
          title: "Clonar",
          showByStatus: [1, 2, 3],
          classIcon: "fa-solid fa-clone",
          eventName: "cloneRent",
        },
        {
          title: "Compartilhar",
          showByStatus: [1, 2, 3],
          classIcon: "fa-sharp fa-regular fa-share-nodes",
          eventName: "printRent",
          hr: true,
        },
        {
          title: "Gerar Faturamento",
          showByStatus: [0],
          classIcon: "fa-solid fa-file-invoice-dollar",
          eventName: "generateBilling",
          style: "color:#38A169;",
          hr: true,
        },
        {
          title: "Gerar Saída",
          showByStatus: [0],
          classIcon: "fa-solid fa-circle-arrow-right",
          eventName: "generateOutput",
          style: "color:#009183;",
        },
        {
          title: "Gerar Retorno",
          showByStatus: [0],
          classIcon: "fa-solid fa-circle-arrow-left",
          eventName: "generateReturn",
          style: "color:#b8860b;",
        },
        {
          title: "Gerar Renovação",
          showByStatus: [0],
          classIcon: "fa-solid fa-retweet",
          eventName: "generateRenewed",
          style: "color:#577696;",
          /* hr: true,*/
        },
        /*   {
             title: "Excluir",
             showByStatus: [1, 2, 3],
             classIcon: "fa-solid fa-trash-can",
             style: "color:red;",
             eventName: "removeRent",
           },*/
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    optionsConditional() {
      let options = [];
      let statusRental = this.rent.statusRental;

      let reserved = this.statusMovimentStock.reserved;
      let reservedAndRented = reserved - this.statusMovimentStock.rented;
      let rented = this.statusMovimentStock.rented;
      let final = this.statusMovimentStock.final;

      let allowOutputGreater =
        this.userLogged.parameterStock.allowOutputGreater;

      var isRuleOperational = this.hasRule("Operational");

      let hasPermissionBilling = this.hasPermission("Billing");
      let isRuleBilling = hasPermissionBilling
        ? hasPermissionBilling.includes("create")
        : false;

      let hasPermissionTaxDocument = this.hasPermission("TaxDocument");
      let isRuleTaxDocument = hasPermissionTaxDocument
        ? hasPermissionTaxDocument.includes("create")
        : false;

      this.options.forEach((item) => {
        if (
          isRuleOperational &&
          item.eventName == "generateOutput" &&
          reservedAndRented > 0 &&
          statusRental == 2
        ) {
          options.push(item);
        } else if (
          isRuleOperational &&
          item.eventName == "generateReturn" &&
          final > 0 &&
          statusRental == 2
        ) {
          options.push(item);
        } else if (
          isRuleOperational &&
          item.eventName == "generateRenewed" &&
          final > 0 &&
          statusRental == 2
        ) {
          options.push(item);
        } else if (
          (isRuleBilling || isRuleTaxDocument) &&
          item.eventName == "generateBilling" &&
          statusRental == 2 &&
          this.totalProductForInvoice > 0
        ) {
          options.push(item);
        } else {
          if (item.showByStatus.includes(statusRental)) options.push(item);
        }
      });

      return options;
    },
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    closeModal() {
      this.modalOptions = { name: "", title: "", width: 0 };
      this.printRent = false;
      this.periodRent = {};
      this.hideModal();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "totalRecordsPeriod")
          this.totalRecordsPeriod = event.data.totalRecords;

        if (event.name == "cloneRent") {
          this.modalOptions = {
            name: "cloneRent",
            title: "Clonar",
            width: 650,
          };
          this.openModal("optionsRent");
        }


        if (event.name == "acceptRent") {
          this.modalOptions = {
            name: "acceptRent",
            title: "Tem certeza que deseja Aprovar?",
            width: 600,
          };
          this.openModal("optionsRent");
        }

        if (event.name == "progressRent") {
          this.modalOptions = {
            name: "progressRent",
            title: "Tem certeza que deseja deixar como Orçamento?",
            width: 600,
          };
          this.openModal("optionsRent");
        }

        if (event.name == "rejectRent") {
          this.modalOptions = {
            name: "rejectRent",
            title: "Tem certeza que deseja Reprovar?",
            width: 600,
          };
          this.openModal("optionsRent");
        }

        if (event.name == "printRent") {
          this.printRent = true;
        }

        if (event.name == "emailSent" || event.name == "signatureSent") {
          this.printRent = false;
          this.periodRent = {};
        }

        if (event.name == "generateBilling") {
          this.$router.push({
            name: "generateBilling",
            params: { rentId: this.rent.id, customerId: this.rent.customer.id },
          });
        }

        if (event.name == "generateOutput") {
          this.$router.push({
            name: "generateOutput",
            params: { id: this.rent.id, periodRentId: "null" },
          });
        }

        if (event.name == "generateReturn") {
          this.$router.push({
            name: "generateReturn",
            params: { id: this.rent.id, periodRentId: "null" },
          });
        }

        if (event.name == "generateRenewed") {
          this.$router.push({
            name: "generateRenewed",
            params: { id: this.rent.id, periodRentId: "null" },
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.select-document {
  min-height: 100px;
}
</style>