<template>
    <div>
        <ListViewWithDataHandler :templateList="templateList" :showHorizontalFilter="false"
            :propsParam="{ any: genericId }" />
        <Modal title="Assinaturas" :width="900" v-show="showModal('signers')">
            <SignerList />
        </Modal>
    </div>
</template>
  
<script>



import ListViewWithDataHandler from "@nixweb/nixloc-ui/src/component/template/ListViewWithDataHandler.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import SignerList from '../signer/SignerList.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
    name: "SignatureFileList",
    props: ['genericId'],
    components: {
        ListViewWithDataHandler,
        Modal,
        SignerList
    },
    data() {
        return {
            templateList: {
                urlGetApi: "/api/v1/signature-file/get-all",
                urlDeleteAllApi: "/api/v1/crm/customer/delete",
                showChecks: false,
                headerTable: [
                    {
                        title: "Emissão",
                        field: "registeredDate",
                        type: "dateTime",
                        iconSearch: false,
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                    },
                    {
                        field: "name",
                        title: "Documento",
                        type: "text",
                        classCssBody: "limit",
                    },
                    {
                        field: "totalSigners",
                        title: "Assinaturas",
                        type: "link",
                        iconSearch: false,
                        eventName: "openSigners",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                    },
                    {
                        field: "source",
                        title: "Origem",
                        type: "text",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                    },
                    {
                        field: "registeredUser",
                        title: "Emitido por",
                        type: "text",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                    },
                    {
                        field: "statusName",
                        title: "Status",
                        type: "class",
                        fieldComparison: "statusName",
                        classCssTitle: "text-center",
                        classCssBody: [
                            {
                                classCss: "text-center badge badge-warning",
                                fieldComparison: "Pendente",
                            },
                            {
                                classCss: "text-center badge badge-success",
                                fieldComparison: "Assinado",
                            },
                            {
                                classCss: "text-center badge badge-info",
                                fieldComparison: "Aberto",
                            },
                            {
                                classCss: "text-center badge badge-danger",
                                fieldComparison: "Recusado",
                            },
                        ],
                    },
                ],
            },
        }
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
        create() {
            this.$router.push({
                name: "customerCreate",
            });
            this.removeLoading(["btnCustomerCreate"]);
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "openSigners")
                    this.openModal("signers");
            },
            deep: true,
        },
    },
};
</script>
  